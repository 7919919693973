! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("opened");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("opened");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-main-content-section").fitVids({ ignore: "nofit" });
	}

	// Add dropdown class to main nav items
	$(".w-dropdown:has('.dropdown-wrapper')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-dd-toggle").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".w-dropdown").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".w-dropdown").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Online banking form
	$(".login-link").mouseover(function() {
		$(".top-nav-dd-list").addClass("w--open");
	}).mouseout(function() {
		$(".top-nav-dd-list").removeClass("w--open");
	});

	if (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
		$(".login-select-dd").mouseover(function() {
			$(".top-nav-dd-list").addClass("w--open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// External links disclaimer - IE does not support startsWith() so I am adding the following definition
	if (!String.prototype.startsWith) {
		String.prototype.startsWith = function(searchString, position) {
			position = position || 0;

			return this.indexOf(searchString, position) === position;
		};
	}

	$.expr[':'].external = function(obj) {
		return !obj.href.match(/^mailto\:/) && !obj.href.match(/^tel\:/) && !obj.href.startsWith('#') && !obj.href.startsWith('javascript:') && !$(obj).hasClass("no-disclaimer") && (obj.hostname != location.hostname);
	};

	$("a:external").click(function(event) {
		event.preventDefault();
		var href = this.href;
		var wWidth = $(window).width();

		$("#dialog-confirm").dialog({
			width: '80%',
			height: 'auto',
			modal: true,
			fluid: true,
			buttons: [
				{
					text: "CLICK HERE TO CONTINUE",
					click: function() {
						window.open(href, "_blank");
						$("#dialog-confirm").dialog("close");
					},
					class: "external-link-warning-button primary"
				},
				{
					text: "Cancel",
					click: function() {
						$("#dialog-confirm").dialog("close");
					},
					class: "external-link-warning-button secondary"
				}
			]
		});
		$(".ui-dialog-titlebar").hide();
		$(".external-link-warning-button.primary").blur();
	});

	// Link with image
	$("a img[src]").parent().css('border','0px');

	/**
	* ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	Webflow.require('ix').init([
		{slug: "ken-burnsslideshow",name: "Ken BurnsSlideshow",value: {style: {},triggers: [{type: "slider",loopA: true,loopB: true,stepsA: [{wait: "15000ms",transition: "transform 15000ms ease 0",scaleX: 1.2,scaleY: 1.2,scaleZ: 1,x: "0px",y: "0px",z: "0px"}],stepsB: [{wait: "20000ms",transition: "transform 2000ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}},
		{slug: "dropdown-hover",name: "Dropdown Hover",value: {style: {},triggers: [{type: "hover",selector: ".main-nav-dd-list",descend: true,stepsA: [{display: "flex",opacity: 1,height: "auto",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}],stepsB: [{display: "none",opacity: 0,height: "0px",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}]},{type: "hover",selector: ".nav-dd-triangle",descend: true,stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "search-dd-mobile",name: "Search DD Mobile",value: {style: {},triggers: [{type: "click",selector: ".mobile-search-dd-wrap",stepsA: [{display: "block",opacity: 1,height: "auto",transition: "height 200 ease 0, opacity 200 ease 0"}],stepsB: [{display: "none",opacity: 0,height: "0px",transition: "height 200 ease 0, opacity 200 ease 0"}]}]}},
		{slug: "dd-widget-smooth-open",name: "DD Widget Smooth Open",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "height 200ms ease 0, opacity 200ms ease 0"}],stepsB: [{height: "0px",transition: "height 100ms ease 0"}]}]}},
		{slug: "tabarrow",name: "TabArrow",value: {style: {},triggers: [{type: "click",selector: ".tab-triangle",stepsA: [{opacity: 1}],stepsB: []}]}},
		{slug: "tab-arrow-current",name: "Tab Arrow Current",value: {style: {},triggers: [{type: "click",selector: ".tab-triangle",descend: true,stepsA: [{opacity: 1,transition: "opacity 200 ease 0"}],stepsB: []}]}},
		{slug: "zoom-in-slide",name: "Zoom In Slide",value: {style: {opacity: 0,x: "0px",y: "-20px",z: "0px",scaleX: 0.5,scaleY: 0.5,scaleZ: 1},triggers: [{type: "slider",stepsA: [{wait: "100ms"},{opacity: 1,transition: "transform 600ms ease-out-back 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: [{opacity: 0,transition: "transform 200 ease 0, opacity 200 ease 0",x: "0px",y: "-20px",z: "0px",scaleX: 0.5,scaleY: 0.5,scaleZ: 1}]}]}},
		{slug: "search-dropdown",name: "Search Dropdown",value: {style: {},triggers: [{type: "click",stepsA: [{height: "auto",transition: "height 300ms ease 0"}],stepsB: [{height: "0px",transition: "height 300ms ease 0"}]}]}},
		{slug: "more-options",name: "More Options",value: {style: {},triggers: [{type: "click",stepsA: [{display: "none",transition: "transform 300ms ease 0"}],stepsB: []},{type: "click",selector: ".less-options-button",stepsA: [{display: "block",transition: "transform 300ms ease 0"}],stepsB: []},{type: "click",selector: ".locations-options-wrapper",stepsA: [{display: "block",transition: "transform 300ms ease 0"}],stepsB: []}]}},
		{slug: "hide-location-options",name: "Hide Location Options",value: {style: {display: "none"},triggers: []}},
		{slug: "less-options",name: "Less Options",value: {style: {display: "none"},triggers: [{type: "click",stepsA: [{display: "none",transition: "transform 300ms ease 0"}],stepsB: []},{type: "click",selector: ".more-options-button",stepsA: [{display: "block",transition: "transform 300ms ease 0"}],stepsB: []},{type: "click",selector: ".locations-options-wrapper",stepsA: [{display: "none",transition: "transform 300ms ease 0"}],stepsB: []}]}},
		{slug: "add-nav-bar-shadow-on-scroll",name: "Add Nav Bar Shadow On Scroll",value: {style: {},triggers: []}},
		{slug: "menu-card-hover",name: "Menu Card Hover",value: {style: {},triggers: [{type: "hover",selector: ".feature-card-bg-image",preserve3d: true,stepsA: [{transition: "transform 300ms ease 0",scaleX: 1.05,scaleY: 1.05,scaleZ: 1}],stepsB: [{transition: "transform 300ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]},{type: "hover",selector: ".feature-card-heading",preserve3d: true,stepsA: [{transition: "transform 300ms ease 0",scaleX: 1.05,scaleY: 1.05,scaleZ: 1}],stepsB: [{transition: "transform 300ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}},
		{slug: "cb-image-hover",name: "CB Image Hover",value: {style: {},triggers: [{type: "hover",stepsA: [{transition: "transform 500ms ease 0",scaleX: 1.03,scaleY: 1.03,scaleZ: 1}],stepsB: [{transition: "transform 500ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}},
		{slug: "slide-caption-load",name: "Slide Caption Load",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",stepsA: [{opacity: 1,transition: "transform 750ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "cb1-reveal",name: "CB1 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "750ms"},{opacity: 1,transition: "transform 750ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "cb2-reveal",name: "CB2 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",preload: true,stepsA: [{wait: "1000ms"},{opacity: 1,transition: "transform 750ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "card-1-reveal",name: "Card 1 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "15%",stepsA: [{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "card-2-reveal",name: "Card 2 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "15%",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "card-3-reveal",name: "Card 3 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "card-4-reveal",name: "Card 4 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "750ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "lower-heading-reveal",name: "Lower Heading Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal",name: "Tab1 Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal-2",name: "Tab1 Reveal 2",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal-3",name: "Tab1 Reveal 3",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal-4",name: "Tab1 Reveal 4",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "750ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "tab1-reveal-5",name: "Tab1 Reveal 5",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "1000ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 400ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "close-alert-box",name: "Close Alert Box",value: {style: {},triggers: [{type: "click",selector: ".alert-box-section-wrap",stepsA: [{display: "none"}],stepsB: []}]}}
	]);
});

// on window resize run function
$(window).resize(function() {
	fluidDialog();
});

// catch dialog if opened within a viewport smaller than the dialog width
$(document).on("dialogopen", ".ui-dialog", function(event, ui) {
	fluidDialog();
});

function fluidDialog() {
	var $visible = $(".ui-dialog:visible");
	// each open dialog
	$visible.each(function() {
		var $this = $(this);
		var dialog = $this.find(".ui-dialog-content").data("ui-dialog");
		// if fluid option == true
		if (dialog.options.fluid) {
			var wWidth = $(window).width();
			// check window width against dialog width
			if (wWidth < (parseInt(dialog.options.maxWidth) + 50)) {
				// keep dialog from filling entire screen
				$this.css("max-width", "90%");
			}
			else {
				// fix maxWidth bug
				$this.css("max-width", dialog.options.maxWidth + "px");
			}
			//reposition dialog
			dialog.option("position", dialog.options.position);
		}
	});
}
